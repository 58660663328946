/**
 * Our References
 *
 * @author Christian Holzinger
 * @copyright (c) Peritus Webdesign GmbH
 */
// NPM
import React, {useEffect, useRef} from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.scss';

// Peritus
import Block from 'components/Block';
import SliderArrow from './SliderArrow';
import ReferenceItem from './ReferenceItem';
import {ResponsiveImageProps} from 'components/ResponsiveImage';
import {PeritusLinkProps} from 'components/PeritusLink';

// Styles
// import * as styles from './references.module.scss';
import {wide} from 'components/Block/block.module.scss';
import './slick-theme.scss';

// Assets
import PrevIcon from './prev.inline.svg';
import NextIcon from './next.inline.svg';

const References = ({mode, references, categories, showImages, showLinks, ...props}) => {
        let items = [];
        const slider = useRef(null);

        /**
         * @param {array} allItems
         * @param {object} item
         *
         * @return {array}
         */
        const addItem = (allItems, item) =>
            !!item ? [
                ...allItems,
                {
                    title: item.title,
                    ...item.acf,
                },
            ] : allItems;

        switch (mode) {
            case 'selection':
                items = references?.length && references.reduce(addItem, []);
                break;
            case 'category':
                items = categories?.length && categories.reduce(
                    (allCatItems, cat) => cat?.references?.nodes?.length
                        ? [...allCatItems, cat.references.nodes.reduce(addItem, [])]
                        : allCatItems,
                    []
                );
                break;
        }

        if (!items.length) {
            return;
        }

        const multiSlideAdaptiveHeight = (old, newIndex) => {
            const slides = slider.current.innerSlider.list.querySelectorAll('.slick-slide');

            const maxHeight = Array
                .from(slides)
                .slice(newIndex, newIndex + 2)
                .reduce((height, element) => element.children[0].children[0].offsetHeight > height
                    ? element.children[0].children[0].offsetHeight : height, 0);

            for (let i = 0; i < slides.length; i++) {
                slides[i].style.height = maxHeight + 'px';
            }

            if (!old && !newIndex) {
                slider.current.innerSlider.list.style.height = maxHeight + 'px';
            }
        };

        let sliderSettings = {
            arrows: true,
            infinite: false,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            adaptiveHeight: true,
            nextArrow: <SliderArrow icon={<NextIcon/>}/>,
            prevArrow: <SliderArrow icon={<PrevIcon/>}/>,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false,
                        dots: true,
                    },
                },
            ],
            beforeChange: multiSlideAdaptiveHeight,
        };

        // Run on init
        useEffect(() => multiSlideAdaptiveHeight(0, 0), []);

        return (
            <Block className={wide} {...props}>
                <Slider {...sliderSettings} ref={slider}>
                    {items && items.map((item, index) => (
                        <ReferenceItem item={item} showImages={showImages} showLinks={showLinks} key={index}/>
                    ))}
                </Slider>
            </Block>
        );
    }
;

const referencesProps = PropTypes.shape({
    title: PropTypes.string.isRequired,
    acf: PropTypes.shape({
        description: PropTypes.string.isRequired,
        period: PropTypes.string,
        budget: PropTypes.string,
        customer: PropTypes.string,
        image: PropTypes.shape(ResponsiveImageProps),
        link: PropTypes.shape(PeritusLinkProps),
    }),
});

References.propTypes = {
    mode: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        references: PropTypes.shape({
            nodes: PropTypes.arrayOf(referencesProps),
        }),
    })),
    references: PropTypes.arrayOf(referencesProps),
    showImages: PropTypes.bool,
    showLinks: PropTypes.bool,
};

References.defaultProps = {
    references: [],
    categories: [],
};

export default References;
