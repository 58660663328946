/**
 * Our Slider Arrow Component
 *
 * @author Christian Holzinger
 * @copyright (c) Peritus Webdesign GmbH
 */
import React from 'react';

const SliderArrow = ({className, style, onClick, icon}) => (
    <div className={className} style={{...style}} onClick={onClick}>
        {icon}
    </div>
);

export default SliderArrow;
