/**
 * Our Reference Item Component
 *
 * @author Christian Holzinger
 * @copyright (c) Peritus Webdesign GmbH
 */
// NPM
import React from 'react';

// Peritus
import ResponsiveImage from 'components/ResponsiveImage';
import PeritusLink from 'components/PeritusLink';

// Styles
import * as styles from './referenceitem.module.scss';

const ReferenceItem = ({item, showImages, showLinks}) => {
    const Parent = showLinks ? PeritusLink : `div`;

    return (
        <div>
            {showImages && <ResponsiveImage image={item.image}/>}
            <Parent className={styles.item} {...item.link}>
                <h3 className={styles.title}>{item.title}</h3>
                <div className={styles.meta}>
                    {item.customer &&
                    <span><strong>Kunde: </strong>{item.customer}</span>}
                    {item.period &&
                    <span><strong>Zeitraum: </strong>{item.period}</span>}
                    {item.budget &&
                    <span><strong>Budget: </strong>{item.budget}</span>}
                </div>
                <div dangerouslySetInnerHTML={{__html: item.description}}/>
            </Parent>
        </div>
    );
}

export default ReferenceItem;
